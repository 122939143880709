// eslint-disable-next-line no-control-regex
export const EMAIL_REGEX = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const NAME_REGEX = /^(?=.{1,32}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/;

export const STREET_REGEX = /^[a-zA-Z0-9#\-,.'\s]{1,50}$/;

export const SECONDARY_REGEX = /^[a-zA-Z0-9#\-,.'\s]{1,50}$/;

export const CITY_REGEX = /^[a-zA-Z0-9#\-,.'\s]{1,100}$/;

export const SSN_REGEX = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$|^(9\d{2})-(5\d|6[0-5]|7\d|8[0-8]|9[0-2,4-9])-(\d{4})$/;
